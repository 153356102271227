import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
//import Where from "@/views/Where.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/where',
    name: 'Where',
    component: () => import(/* webpackChunkName: "where" */ '../views/Where.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/dimensions',
    name: 'Dimensions',
    component: () => import(/* webpackChunkName: "where" */ '../views/Dimensions.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/readiness',
    name: 'Readiness',
    component: () => import(/* webpackChunkName: "where" */ '../views/Readiness.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/data',
    name: 'Data',
    component: () => import(/* webpackChunkName: "where" */ '../views/Data.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/content',
    name: 'Content',
    component: () => import(/* webpackChunkName: "where" */ '../views/Content.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/omnichannel',
    name: 'Omnichannel',
    component: () => import(/* webpackChunkName: "where" */ '../views/Omnichannel.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/insights',
    name: 'Insights',
    component: () => import(/* webpackChunkName: "where" */ '../views/Insights.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "where" */ '../views/Report.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "where" */ '../views/Thanks.vue'),
    meta: { transition: 'slide-left' },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
