<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
//import Layout from './components/Layout.vue'

export default {
  name: "App",
  title: "Adobe Customer Journey",
  components: {
    //    Layout,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  max-width: 1300px;
  margin: 0 auto;
  text-align: left;
}

.page-content {
  padding: 70px 20px;
  background: #fff;
}
</style>
