<template>
  <div id="app">
    <TopBar />
    <main class="main-slot page-content">
      <div class="page-width">
        <div class="copy">
          <h1>
            <strong
              >Customer Journey Management (CJM) – how mature is your brand? </strong
            ><br />
            Most brands’ ability to orchestrate personalized customer journeys
            is evolving. Some possess an advancing maturity. Fewer are leading.
          </h1>
          <h2>How is your brand doing?</h2>
          <img
            src="@/assets/slide-01-mobile.png"
            alt=""
            width="912"
            class="slide-01 mobile"
          />
          <router-link class="cta" to="/where">Next</router-link>
        </div>

        <img
          src="@/assets/slide-01.png"
          alt=""
          width="912"
          class="slide-01 desktop"
        />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  title: "Adobe Customer Journey",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
$hl-color: #eb1000;

@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Regular.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Regular.svg#6cd9048a14b0e2f8daead838196d8a9d")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Bold.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Bold.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Bold.svg#bb4fbb99c3a3d8d572bd881924533371")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../assets/fonts/AdobeClean-Light.eot");
  /* IE9 Compat Modes */
  src: url("../assets/fonts/AdobeClean-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AdobeClean-Light.woff") format("woff"),
    url("../assets/fonts/AdobeClean-Light.ttf") format("truetype"),
    url("../assets/fonts/AdobeClean-Light.svg#37b1bad62db9b227511ca6c262f28acc")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Adobe Clean", Arial, Helvetica, sans-serif;
  color: #000;
  background: #fafafa;
}

.page-width {
  max-width: 1300px;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.page-content {
  padding: 70px 20px 0 20px;
  height: 964px;
  background-color: #fff;
  position: relative;
  @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

.copy {
  width: 550px;
  max-width: 45%;
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }
  h1 {
    font-size: 40px;
    font-weight: 400;
    strong {
      display: inline-block;
      font-size: 41px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 800px) {
      font-size: 28px;
      strong {
        font-size: 28px;
      }
    }
  }
  h2 {
    color: $hl-color;
    font-size: 60px;
    line-height: 75px;
    font-weight: 400;
    max-width: 400px;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 41px;
      line-height: 120%;
      font-weight: 500;
    }
  }
}

img.slide-01 {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 65%;
  &.mobile {
    display: none;
    position: relative;
    max-width: 100%;
    margin-top: 30px;
  }
  @media screen and (max-width: 800px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
}

.cta {
  display: inline-block;
  background: $hl-color;
  position: relative;
  height: 84px;
  line-height: 84px;
  color: #fff;
  font-size: 37px;
  font-weight: 700;
  text-decoration: none;
  padding: 0 190px 0 150px;
  border-radius: 42px;
  margin: 80px 0 0 0;
  &::after {
    content: "";
    width: 24px;
    height: 29px;
    background: url(../assets/cta-arrow.svg);
    position: absolute;
    top: 29px;
    right: 148px;
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
    padding: 0 150px 0 100px;
    margin-top: 50px;
    height: 71px;
    line-height: 71px;
    font-size: 600;
    &::after {
      right: 100px;
      top: 22px;
    }
  }
}
</style>