import Vue from "vue";
import App from "./App.vue";
import TopBar from "./components/TopBar.vue";
import Footer from "./components/Footer.vue";
import router from "./router";

Vue.component("topBar", TopBar);
Vue.component("Footer", Footer);

Vue.prototype.$quiz_selections = {
  section_1_q1: 1,
  section_2_q1: 1,
  section_3_q1: 1,
  section_4_q1: 1,
  section_5_q1: 1,
};

Vue.prototype.$rankings = {
  readiness: 3,
  data: 3,
  content: 3,
  omnichannel: 3,
  insights: 3,
};

Vue.prototype.$rank = {
  readiness: 1,
  data: 1,
  content: 1,
  omnichannel: 1,
  insights: 1,
};

Vue.prototype.$form_data = {
  firstname: null,
  lastname: null,
  title: null,
  phone: null,
  email: null,
  company: null,
  industry: null,
  country: null,
  report_url: null,
};

Vue.prototype.$assessment = "unknown";

Vue.prototype.$reportUrl = {
  url: "https://adobe-journey-report.viewstream-media.com/report.php?readiness=1&data=1&insights=1&content=1&omnichannel=1&rating=evolving",
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
